<template>
   <div id="home">
      <!-- 定义图表外层容器 -->
      <div id="myHomeChart2"
           ref="homeEcharts"></div>
   </div>
</template>
<script>
import api from "../../http/api";
export default {
   data () {
      return {
         myChart: "",
         myChart2: "",
         data: [],

      };
   },
   created () {
      this.initData();
   },
   mounted () {
      // 调用当前图表
      this.initData();
      this.dataChart();
      // this.p();
      // console.log("man", this.man);
      // console.log("woman", this.woman);
   },
   updated () {
      // this.dataChart();
   },
   computed: {

   },

   methods: {
      // 绘制图表
      dataChart () {
         //初始化图表，this.$refs.homeEcharts获取到图表容器
         this.myChart = this.$echarts.init(this.$refs.homeEcharts);
         // 初始化配置（官网实例详情左侧代码，直接复制过来按项目需求修改即可）
         this.myChart.setOption({
            title: {
               text: "患者年龄阶段统计",
               x: "center",
            },
            //具体点击某一项触发的样式内容
            tooltip: {
               trigger: "item",
               formatter: "{a} <br/>{b} : {c} ({d}%)",
               formatter: function (data) {
                  if (data.name === "少儿") {
                     return data.name + '<br/>' + data.seriesName + '：' + (data.value) + '<br/>' + "年龄段：" + "7-13" + '<br/>' + '占比：' + data.percent + "%"
                  }
                  if (data.name === "青少年") {
                     return data.name + '<br/>' + data.seriesName + '：' + (data.value) + '<br/>' + "年龄段：" + "14-18" + '<br/>' + '占比：' + data.percent + "%"
                  }
                  if (data.name === "青年") {
                     return data.name + '<br/>' + data.seriesName + '：' + (data.value) + '<br/>' + "年龄段：" + "19-45" + '<br/>' + '占比：' + data.percent + "%"
                  }
                  if (data.name === "中年") {
                     return data.name + '<br/>' + data.seriesName + '：' + (data.value) + '<br/>' + "年龄段：" + "46-65" + '<br/>' + '占比：' + data.percent + "%"
                  }
                  if (data.name === "老年") {
                     return data.name + '<br/>' + data.seriesName + '：' + (data.value) + '<br/>' + "年龄段：" + "65以上" + '<br/>' + '占比：' + data.percent + "%"
                  }
               }
            },
            //左上侧分类条形符
            legend: {
               orient: "vertical",
               left: "left",
               data: [

               ],
            },
            //饼状图类型以及数据源
            series: [
               {
                  name: "统计数量",
                  type: "pie",
                  // radius: '70%',
                  // center: ['50%', '60%'],

                  //设置饼状图扇形区域的样式
                  itemStyle: {
                     emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                     },
                  },
               },
            ],
         });
         // 把参数配置放到容器里
         // myChart.setOption(option, true);
         // myChart.setOption(option);
      },
      async initData () {
         let data = await api.patient.getPatient({
            cur: 1,
            size: 100000000,
         });
         if (data.data.msg == "请求处理正常") {
            this.data = data.data.data.records;
            let juvenile = this.data.filter(
               (item) => item.age >= 7 && item.age < 13
            ).length;
            let teenagers = this.data.filter(
               (item) => item.age >= 13 && item.age < 18
            ).length;
            let youth = this.data.filter((item) => item.age >= 18 && item.age < 45)
               .length;
            let middle = this.data.filter((item) => item.age >= 45 && item.age < 65)
               .length;
            let old = this.data.filter((item) => item.age >= 65).length;
            this.myChart.setOption({
               legend: {
                  orient: "vertical",
                  left: "left",
                  data: [
                     {
                        value: juvenile,
                        name: "少儿",
                        selected: true,
                        itemStyle: { color: "#79D3DD" },
                     },
                     {
                        value: teenagers,
                        name: "青少年",
                        selected: true,
                        itemStyle: { color: "#F9C7FA" },
                     },
                     {
                        value: youth,
                        name: "青年",
                        selected: true,
                        itemStyle: { color: "#afb4db" },
                     },
                     {
                        value: middle,
                        name: "中年",
                        selected: true,
                        itemStyle: { color: "#fedcbd" },
                     },
                     {
                        value: old,
                        name: "老年",
                        selected: true,
                        itemStyle: { color: "#77ac98" },
                     },
                  ],
               },
               series: [
                  {
                     data: [
                        {
                           value: juvenile,
                           name: "少儿",
                           selected: true,
                           itemStyle: { color: "#79D3DD" },
                        },
                        {
                           value: teenagers,
                           name: "青少年",
                           selected: true,
                           itemStyle: { color: "#F9C7FA" },
                        },
                        {
                           value: youth,
                           name: "青年",
                           selected: true,
                           itemStyle: { color: "#afb4db" },
                        },
                        {
                           value: middle,
                           name: "中年",
                           selected: true,
                           itemStyle: { color: "#fedcbd" },
                        },
                        {
                           value: old,
                           name: "老年",
                           selected: true,
                           itemStyle: { color: "#77ac98" },
                        },
                     ],
                  },
               ],
            });
         }
      },
      drawCharts () {
         this.dataChart();
      },
      // 销毁实例(看项目需要进行销毁实例)
      destroyChart () {
         this.$echarts.init(this.$refs.homeEcharts).dispose();
      },
   },
};
</script>
<style scoped>
/* 给图表容器定义宽高 */
#myHomeChart2 {
   width: 400px;
   height: 300px;
}
</style>

