<template>
   <div>
      <el-card class="box-card">
         <div class="people">
            <img src="../assets/img/数据可视化1.png" />
            <div>
               <p class="p1">患者人数</p>
               <p class="p2">{{ total + "人" }}</p>
            </div>
         </div>
      </el-card>

      <!-- 定义图表外层容器 -->
      <div id="box">
         <div id="myHomeChart"
              ref="homeEcharts"></div>
         <AgePie />
      </div>
   </div>
</template>
<script>
import AgePie from "../views/echarts/AgePie";
import api from "../http/api";
export default {
   components: {
      AgePie,
   },
   data () {
      return {
         myChart: "",
         myChart2: "",
         data: [],
      };
   },
   created () {
      this.initData();
   },
   mounted () {
      // 调用当前图表
      this.initData();
      this.dataChart();
      // this.p();
      // console.log("man", this.man);
      // console.log("woman", this.woman);
   },
   updated () {
      // this.dataChart();
   },
   computed: {
      total () {
         return this.data.length;
      },
   },

   methods: {
      // 绘制图表
      dataChart () {
         //初始化图表，this.$refs.homeEcharts获取到图表容器
         this.myChart = this.$echarts.init(this.$refs.homeEcharts);
         // 初始化配置（官网实例详情左侧代码，直接复制过来按项目需求修改即可）
         this.myChart.setOption({
            title: {
               text: "患者性别统计",
               x: "center",
            },
            //具体点击某一项触发的样式内容
            tooltip: {
               trigger: "item",
               formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            //左上侧分类条形符
            legend: {
               orient: "vertical",
               left: "left",
               data: [


               ],

            },
            //饼状图类型以及数据源
            series: [
               {
                  name: "统计数量",
                  type: "pie",
                  //radius: '70%',
                  //center: ['50%', '60%'],

                  //设置饼状图扇形区域的样式
                  itemStyle: {
                     emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                     },
                  },
               },
            ],
         });
         // 把参数配置放到容器里
         // myChart.setOption(option, true);
         // myChart.setOption(option);
      },
      async initData () {
         let data = await api.patient.getPatient({
            cur: 1,
            size: 100000000,
         });
         if (data.data.msg == "请求处理正常") {
            this.data = data.data.data.records;
            // console.log("全部数据", this.data);

            let dataManVal = this.data.filter((item) => item.sex === "男").length;
            let dataWoManVal = this.data.filter((item) => item.sex === "女").length;
            this.myChart.setOption({
               legend: {
                  orient: "vertical",
                  left: "left",
                  data: [
                     {
                        value: dataManVal,
                        name: "男性",
                        selected: true,
                        itemStyle: { color: "#79D3DD" },
                     },
                     {
                        value: dataWoManVal,
                        name: "女性",
                        selected: true,
                        itemStyle: { color: "#F9C7FA" },
                     },
                  ],
               },
               series: [
                  {
                     data: [
                        {
                           value: dataManVal,
                           name: "男性",
                           selected: true,
                           itemStyle: { color: "#79D3DD" },
                        },
                        {
                           value: dataWoManVal,
                           name: "女性",
                           selected: true,
                           itemStyle: { color: "#F9C7FA" },
                        },
                     ],
                  },
               ],
            });
         }
      },
      drawCharts () {
         this.dataChart();
      },

      // 销毁实例(看项目需要进行销毁实例)
      destroyChart () {
         this.$echarts.init(this.$refs.homeEcharts).dispose();
      },
   },
};
</script>
<style scoped lang='scss'>
#box {
   width: 80%;
   display: flex;
   margin: auto;
   div {
      margin: 20px;
   }
}
/* 给图表容器定义宽高 */
#myHomeChart {
   width: 400px;
   height: 300px;
}
.box-card {
   width: 80%;
   margin: auto;
   box-shadow: 5px 5px 10px gray;
}
.people {
   border-radius: 10px;
   height: 100px;
   width: 200px;
   background: #ff7970;
   display: flex;
   align-items: center;
   img {
      width: 70px;
      height: 70px;
   }
   p {
      margin: 0;
      padding: 0;
      color: #fff;
      margin: 10px;
   }
   .p2 {
      font-size: 18px;
      font-weight: 600;
   }
}
</style>

